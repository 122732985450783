@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: 'IntelOneLight';
	src: local('IntelOneDisplayLight'),
		url('./assets/fonts/IntelOneDisplayLight.ttf') format('truetype');
}
@font-face {
	font-family: 'IntelOneReg';
	src: local('IntelOneDisplayRegular'),
		url('./assets/fonts/IntelOneDisplayRegular.ttf') format('truetype');
}
@font-face {
	font-family: 'IntelOneBold';
	src: local('IntelOneDisplayBold'),
		url('./assets/fonts/IntelOneDisplayBold.ttf') format('truetype');
}

html {
	scroll-behavior: smooth;
	overflow-y: auto;
}

body {
	margin: 0;
	font-family: 'IntelOneLight', 'IntelOneReg', 'Roboto';
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	min-height: 100vh;
}

code {
	font-family: 'IntelOneLight', 'Courier New';
}
