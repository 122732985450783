@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
	--foreground-rgb: 0, 0, 0;
	--background-start-rgb: 214, 219, 220;
	--background-end-rgb: 255, 255, 255;
}

@layer base {
	.custom-scrollbar {
		scrollbar-color: black #1e293b;
		scrollbar-width: thin;
	}
	.custom-scrollbar::-webkit-scrollbar-track {
		@apply bg-slate-800;
	}

	.custom-scrollbar::-webkit-scrollbar {
		@apply w-1;
	}

	.custom-scrollbar::-webkit-scrollbar-thumb {
		@apply bg-black;
	}
	.modal-backdrop {
		background: linear-gradient(
			117deg,
			rgba(193, 205, 213, 0.2) 0%,
			rgba(186, 186, 186, 0.2) 38.02%,
			rgba(216, 228, 244, 0.2) 62.5%
		);
	}
	.heading {
		font-family: IntelOneBold;
		font-weight: 900;
	}
	.title {
		font-family: IntelOneReg;
		font-size: 18px;
	}
	.subtitle {
		font-family: IntelOneLight;
	}
}

@layer utilities {
	.blur {
		background: linear-gradient(
			0deg,
			rgba(255, 255, 255, 0.04) 0%,
			rgba(255, 255, 255, 0) 56.08%
		);
	}
	.bg-color {
		background: #161c24;
	}
}

/* ---Chrome, Safari and Opera---*/
.scrollbar-hide::-webkit-scrollbar {
	display: none;
}

/* ---IE, Edge and Firefox--- */
.scrollbar-hide {
	-ms-overflow-style: none;
	scrollbar-width: none;
}
